import modules from "./_modules";

modules();

$(document).ready(function(){
    $('#cellphone').mask('(00) 00000-0000');
});



function formValidation(name, cellphone, email, store, message){
    let nameValid = true
    let cellphoneValid = true
    let emailValid = true
    let storeValid = true
    let messageValid = true

    let nameError = document.getElementById('nameError');
    let cellphoneError = document.getElementById('cellphoneError');
    let emailError = document.getElementById('emailError');
    let storeError = document.getElementById('storeError');
    let messageError = document.getElementById('messageError');

    //check name
    const nameExpression = /[a-zA-Z]/
    if(name.value.length < 3 || !name.value.replace(/\s/g, '').length || !nameExpression.test(name.value) ){
        name.classList.add('error');
        var errorText = "O nome é inválido";
        nameError.innerText= errorText;
        nameError.style.display = 'block';
        nameValid = false
    }else {
        name.classList.remove('error');
        nameError.style.display = 'none';
        nameValid = true
    }

    //check phone
    if(cellphone.value.length < 15 || !cellphone.value.replace(/\s/g, '').length){
        cellphone.classList.add('error');
        var errorText = "O telefone é inválido";
        cellphoneError.innerText= errorText;
        cellphoneError.style.display = 'block';
        cellphoneValid = false
    }else {
        cellphone.classList.remove('error');
        cellphoneError.style.display = 'none';
        cellphoneValid = true
    }

    //check email
    const emailExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(email.value.length < 3 || !emailExpression.test(String(email.value).toLowerCase())){
        email.classList.add('error');
        var errorText = "O email é inválido";
        emailError.innerText= errorText;
        emailError.style.display = 'block';
        emailValid = false
    }else {
        email.classList.remove('error');
        emailError.style.display = 'none';
        emailValid = true
    }

    //check store
    if(store.value.length < 3 || !store.value.replace(/\s/g, '').length || !nameExpression.test(store.value)){
        store.classList.add('error');
        var errorText = "O nome da loja é inválido";
        storeError.innerText= errorText;
        storeError.style.display = 'block';
        storeValid = false
    }else {
        store.classList.remove('error');
        storeError.style.display = 'none';
        storeValid = true
    }

    //check message
    if(message.value.length < 3 || !message.value.replace(/\s/g, '').length){
        message.classList.add('error');
        var errorText = "Mensagem inválida";
        messageError.innerText= errorText;
        messageError.style.display = 'block';
        messageValid = false
    }else {
        message.classList.remove('error');
        messageError.style.display = 'none';
        messageValid = true
    }

    if(nameValid && cellphoneValid && emailValid && storeError && messageValid){
        return true
    }else{
        return false
    }
}

let form = document.getElementById('contact-form');
if(form){
    const api = axios.create({
        baseURL: 'https://api.logaroo.com.br/v1',
    });
    form.addEventListener('submit', async function(event){
        event.preventDefault()

        let name = document.getElementById('name')
        let cellphone = document.getElementById('cellphone')
        let email = document.getElementById('email')
        let store = document.getElementById('store')
        let message = document.getElementById('message')
        let successMessage = document.getElementById('successMessage')


        if(formValidation(name, cellphone, email, store, message)){
            try{
                
                await api.post(`/contact-email-logaroo`, {
                    name: name.value,
                    cellphone: cellphone.value,
                    email: email.value,
                    store: store.value,
                    message: message.value,
                })

                successMessage.classList.add('shown');
                setTimeout(() => {
                    successMessage.classList.add('opacity')
                }, 100);

                setTimeout(() => {
                    successMessage.classList.remove('opacity')
                    successMessage.classList.remove('shown')
                }, 3000);

                form.reset();
                grecaptcha.reset();
                recaptchaReload();
            } catch(error){
                console.log(error)
            }
        } 
    })
}

