import { Swiper, Navigation, Pagination, Autoplay, EffectFade } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

export default () => {
    var swiper = new Swiper('.gallery-modal-slider', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    });

    var swiper = new Swiper(".partners-slider", {
      pagination: {
        el: ".swiper-pagination",
      },
    });

    var swiper = new Swiper(".mercadoo-benefits__slider", {
      pagination: {
        el: ".mercadoo-benefits-pagination",
      },
      navigation: {
        nextEl: '.benefits-slide-next',
        prevEl: '.benefits-slide-prev',
      },
    });

    var swiper = new Swiper(".testimonials__slider", {
      loop: true,
      pagination: {
        el: ".testimonials-pagination",
      },
      navigation: {
        nextEl: '.testimonials-slide-next',
        prevEl: '.testimonials-slide-prev',
      },
      spaceBetween: 10,
      slidesPerView: 3,
      breakpoints: {
        '1199': {
          slidesPerView: 1,
        },
      }
    });

    var swiper = new Swiper(".success-cases__slider", {
      loop: true,
      pagination: {
        el: ".success-cases-pagination",
      },
      navigation: {
        nextEl: '.success-cases__slide-next',
        prevEl: '.success-cases__slide-prev',
      },
      spaceBetween: 10,
      slidesPerView: 3,
      breakpoints: {
        '1199': {
          slidesPerView: 1,
        },
      }
    });

    var swiper = new Swiper(".how-works-desktop", {
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
    });

    var swiper = new Swiper(".how-works-mobile", {
      loop: true,
      pagination: {
        el: ".how-works-mobile-pagination",
      },
    });

    var swiper = new Swiper(".delivery-how-works__slide", {
      loop: true,
      pagination: {
        el: ".delivery-mobile-pagination",
      },
    });
};
